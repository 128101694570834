
  export const style={
    inpuStyle:{
      colorInput:"#31C5FE"
    },
    url:{
      urlClient:"https://ctverso.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/primary_logo_client_ctverso.png",
      secondaryLogonLogo: "https://timcoo-geral.s3.amazonaws.com/Logos/footer_logo_login_screen.png",
    },
    variables:{
       customMenuItem: false,
    },
    CurrencyName:{
      name:"CT Ponto/CT Pontos"
    },
    teamName:{
      name:"ctverso"
    },
    hasToken: true,
    hostHeader: "ctverso"
  }
